// Read important global variables from env vars
export const API_URL: string = process.env.REACT_APP_API_URL || "";
export const AUTH_USERNAME: string = process.env.REACT_APP_AUTH_USERNAME || "";
export const AUTH_PASSWORD: string = process.env.REACT_APP_AUTH_PASSWORD || "";
export const ADMIN_USERNAME_HASH: string = process.env.REACT_APP_ADMIN_USERNAME_HASH || "";
export const ADMIN_PASSWORD_HASH: string = process.env.REACT_APP_ADMIN_PASSWORD_HASH || "";
export const IS_AUTH_KEY: string = "hooper_admin_is_authenticated";
export const REPLICATE_FENCPOST_MODEL: string = process.env.REACT_APP_REPLICATE_FENCPOST_MODEL || "";
export const REPLICATE_VIDEO_MODEL: string = process.env.REACT_APP_REPLICATE_VIDEO_MODEL || "";

// Session states
export enum SessionStatus {
  CREATED = "created",
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  WAITING = "waiting",
  PROCESSED = "processed",
  TAGGED = "tagged",
  ERROR = "error"
}

export const HOOPER_BLUE = "#5040FF";
export const HOOPER_RED = "#E52E4D";
export const HOOPER_YELLOW = "#F0B031";
export const HOOPER_GREEN = "#1CBE49";
export const OFF_WHITE = "#F2F2F2";
export const HOOPER_LIGHT_BLUE = "#80B5FF";

export const SHOT_STATUS_PENDING: number = 0;
export const SHOT_STATUS_MAKE: number    = 1;
export const SHOT_STATUS_MISS: number    = 2;